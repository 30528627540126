@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?3lsbqt");
  src: url("../fonts/icomoon.eot?3lsbqt#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.woff2?3lsbqt") format("woff2"),
    url("../fonts/icomoon.ttf?3lsbqt") format("truetype"),
    url("../fonts/icomoon.woff?3lsbqt") format("woff"),
    url("../fonts/icomoon.svg?3lsbqt#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fb:before {
  content: "\e902";
}
.icon-in:before {
  content: "\e903";
}
.icon-inst:before {
  content: "\e904";
}
.icon-mail:before {
  content: "\e905";
}
.icon-tel:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e907";
}
.icon-arrow:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}

h1,
h2,
h3 {
  font-weight: 300;
  font-family: var(--font-second);
}

h1 {
  font-size: ac(54px, 48px);
  font-weight: 700;

  line-height: 133%;

  @mixin desk-md {
    font-size: 60px;
  }
}

h2 {
  font-size: ac(42px, 30px);
  font-weight: 300;
  line-height: 115%;

  @mixin desk-md {
    font-size: 48px;
  }
}

h3 {
  font-size: ac(32px, 30px);

  @mixin desk-md {
    font-size: 40px;
  }
}

p,
a {
  font-size: 15px;

  @mixin desk-md {
    font-size: 18px;
  }
}

p {
  line-height: 170%;
  font-weight: 300;
}

a {
  font-weight: 500;
  display: inline-block;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: var(--gold);
    margin-bottom: 16px;

    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }

  h1 {
    font-size: ac(40px, 38px);

    @mixin desk-md {
      font-size: 45px;
    }
  }

  h2 {
    font-size: ac(32px, 30px);

    @mixin desk-md {
      font-size: 38px;
    }
  }

  h3 {
    font-size: ac(28px, 24px);

    @mixin desk-md {
      font-size: 32px;
    }
  }

  h4 {
    font-size: ac(26px, 22px);

    @mixin desk-md {
      font-size: 28px;
    }
  }

  h5 {
    font-size: ac(24px, 20px);

    @mixin desk-md {
      font-size: 26px;
    }
  }

  h6 {
    font-size: ac(20px, 18px);

    @mixin desk-md {
      font-size: 22px;
    }
  }

  p {
    & + img {
      margin-top: 14px;

      & + img {
        margin-top: 14px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  p,
  a {
    font-size: ac(19px, 16px);
    line-height: 150%;
  }

  a {
    display: inline;
    text-decoration: underline;
    word-break: break-all;
    transition: 0.2s;
    color: var(--gold);
    font-weight: 500;

    &:hover {
      opacity: 0.7;
    }
  }

  img {
    margin: 10px 0;
    display: inline-block;
    object-fit: cover;

    & + {
      h1,
      h2,
      h3,
      h4 {
        margin-top: 0 !important;
      }
    }

    &:last-of-type {
      width: 100%;
    }

    @mixin media 651 {
      display: block;
      width: 100% !important;
      margin: 0;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 28px;
  }

  li {
    font-weight: 400;
    font-size: ac(19px, 16px);
    line-height: 182%;
    word-break: break-word;

    &::marker {
      color: var(--gold);
    }
  }

  ul {
    list-style: disc;
    padding-left: 28px;
  }
}
