html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--white);
  color: var(--black);
  font-family: var(--font-main);
  overflow-x: hidden;
}

.swiper-wrapper {
  left: 0 !important;
}

.fp-watermark {
  display: none;
}

#site {
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 987px;
  width: perc(987);

  &.lg {
    max-width: 1280px;
    width: perc(1280);
  }

  &.md {
    max-width: 1000px;
    width: perc(1000);
  }

  @mixin media 1200 {
    width: 89% !important;
  }
}

section {
  position: relative;
  overflow-x: hidden;
}

.socials-link {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--gold);
  color: var(--black);
  font-size: 16px;
  transition: background 0.3s ease;

  i {
    transition: color 0.3s ease;
  }

  &:hover {
    background: var(--black);
    i {
      color: var(--gold);
    }
  }
}

.simplebar-scrollbar::before {
  background-color: var(--black);
}

.footer {
  .hover-link {
    &:before {
      background: var(--black);
    }
  }

  &.bg-gold {
    border-bottom: 8px solid var(--white);
    padding-bottom: 16px;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 16px;
      background: var(--grey);
      bottom: 0;
      left: 0;
    }
  }
}

.hover-link {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    right: 0;
    background: var(--white);
    border-radius: 10px;
    transition: 0.25s ease-in-out;
  }

  /* Hovering link */
  &:hover {
    &:before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
}

.acc-content {
  transition: 0.25s linear;
  overflow: hidden;

  &.values-list__item-desc {
    max-height: 155px;
  }
}

.acc-trigger {
  cursor: pointer;

  &.open {
    i {
      transform: rotate(-90deg) !important;
    }
  }

  &.value-btn {
    display: flex;
    align-items: center;

    i {
      font-size: 10px;
      margin-left: 5px;
      transition: 0.3s ease;
    }

    &:hover {
      i {
        margin: 10px;
      }
    }
  }
}

.contact-section {
  margin-top: 240px;
  background: var(--gold);
  overflow: visible;

  &__inner {
    transform: translateY(-118px);
    display: flex;
    justify-content: center;

    @mixin media 1025 {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }
  }

  .contact-content {
    background: var(--light-grey);
    padding: ac(50px, 24px) 20px ac(50px, 24px) ac(50px, 24px);
    width: 100%;

    h2 {
      margin-bottom: 23px;
    }

    .content-element {
      max-height: 200px;
      width: 100%;
      padding-right: 20px;

      p {
        font-family: var(--font-second);
        line-height: 147%;
      }
    }
  }

  .contact-slider {
    max-width: 475px;
    height: ac(334px, 235px, 375, 651);
    width: 100%;
    flex-shrink: 0;
    margin-left: 20px;

    @mixin media 1025 {
      margin: 0 0 20px 0;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-inactive-color: var(--white);
  --swiper-pagination-color: var(--gold);
}

.contact-footer {
  display: flex;
  align-items: flex-end;
}

@media screen and (min-height: 1200px) and (min-width: 1025px) {
  .contact-footer {
    min-height: 30vh !important;
  }

  .hero-screen_contact {
    min-height: 44vh !important;
  }
}

/* Homepage */
.hero-screen {
  min-height: ac(800px, 218px, 375, 1200);

  @mixin min-media 2200 {
    min-height: 1080px;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(
        0deg,
        rgba(28, 28, 16, 0.25) 0%,
        rgba(255, 90, 94, 0) 100%
      );
      pointer-events: none;
    }
  }

  &__scroll {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    left: 80px;
    cursor: pointer;

    p {
      writing-mode: vertical-rl;
      font-family: var(--font-second);
      font-size: 12px;
      color: var(--white);
      margin-bottom: 21px;
      animation: slide 1.2s linear infinite alternate;
      transition: 0.25s ease;
    }

    .bar {
      position: relative;
      height: 76px;
      width: 1px;
      background: linear-gradient(
        0deg,
        rgba(79, 81, 79, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );

      .fill {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }

    &:hover {
      p {
        color: var(--gold);
      }
    }

    @mixin media 1200 {
      left: 20px;
    }

    @mixin media 1025 {
      display: none;
    }
  }

  &_interior {
    min-height: 500px;
    display: flex;
    align-items: flex-end;
    overflow: visible;
    z-index: 10;
    padding-bottom: 168px;

    &.hero-screen_contact {
      min-height: 467px;
    }

    @mixin media 551 {
      min-height: 300px;
      padding: 100px 0 90px;
    }

    .cont:not(.hero-screen_contact .cont) {
      max-width: 1064px;
      width: perc(1064);
    }

    .hero-screen__bg:before {
      display: none;
    }

    h1 {
      color: var(--white);
      font-weight: 600;
    }
  }

  &_contact {
    padding-bottom: 0;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &__logo {
    max-width: 532px;
    width: 100%;
    padding: 20px 130px 20px 33px;
    position: relative;
    background: var(--white);
    border-right: 10px solid var(--gold);

    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 90px;
      height: 100%;
      background: var(--grey);

      @mixin media 769 {
        width: 40px;
      }
    }
  }
}

.intro-section {
  margin-top: ac(-122px, -35px);
  z-index: 10;

  &_interior {
    margin: ac(-130px, -35px) auto 0;
    overflow: hidden;

    .cont {
      max-width: 1064px;
      width: perc(1064);
    }

    .intro-content {
      max-width: 1064px;
      position: relative;
      padding: 24px 130px 24px 24px;
      min-height: 132px;
      background: var(--white);
      border-right: 10px solid var(--gold);
      transition: 0.25s linear;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 90px;
        height: 100%;
        background: var(--grey);
      }

      .content-element {
        max-width: 918px;

        p {
          font-size: ac(20px, 16px);
          font-family: var(--font-second);
          line-height: 140%;
        }

        &.acc-content {
          &.content-element {
            @mixin media 551 {
              max-height: 112px;
            }

            @mixin min-media 551 {
              max-height: auto !important;
            }
          }
        }
      }

      @mixin media 769 {
        padding: 16px 60px 16px 16px;

        &:before {
          width: 40px;
        }
      }
    }
  }
}

.intro-block {
  position: relative;
  padding: 47px 150px 0px 68px;
  background: var(--white);
  border-right: 30px solid var(--gold);
  min-height: 140px;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 120px;
    height: 100%;
    background: var(--grey);
  }

  .content-element {
    font-size: ac(18px, 15px);
    font-weight: 300;
    line-height: 170%;

    p {
      font-size: ac(18px, 15px);
      line-height: 170%;
    }

    &.acc-content {
      @mixin media 551 {
        max-height: 100px;
      }
    }
  }

  @mixin media 769 {
    padding: 16px 54px 16px 16px;
    border-width: 10px;

    &:before {
      width: 40px;
    }
  }
}

.values-section {
  padding: 118px 0 ac(124px, 60px);
}

.partners-section {
  padding-bottom: ac(124px, 70px);
}

.clients-section {
  padding-bottom: ac(100px, 140px, 768, 1440);

  @mixin media 360 {
    padding-bottom: 220px;
  }
}

#bold-credits {
  width: 150px;
  transition: transform ease-in-out 0.25s;

  &:hover {
    transform: scale(1.1);
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: 60px 0 ac(150px, 50px);
}
.single-page-404 {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;

  border-bottom: 10px solid var(--gold);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16px;
    background: var(--grey);
  }

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }
}
