/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: ac(130px, 80px);
  padding: ac(25px, 15px) 0;
  display: flex;
  align-items: center;
  font-family: var(--font-second);

  &.fixed {
    position: fixed;
  }

  &.scrolled {
    background: rgba(var(--black-rgb), 0.8);
    backdrop-filter: blur(10px);
    height: ac(100px, 60px);

    .logo {
      width: ac(130px, 60px);
    }
  }

  .logo {
    height: 100%;
    width: ac(220px, 148px);
    flex-shrink: 0;
    transition: transform 0.25s ease;
    transform: translateY(12px);

    &:hover {
      transform: scale(1.06) translateY(12px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .navbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;
  }

  .menu-item {
    margin-right: 20px;
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: "\e999";
          font-family: "icomoon";
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @media (--mobile-menu-end-point) {
        padding: 5px 0;
      }

      &:hover {
        @media (--mobile-menu-end-point) {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: ac(16px, 14px);
    @mixin transition-all;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--white);
      @mixin transition-all;
    }

    &:hover {
      color: var(--primary);

      &::before {
        width: 100%;
        left: 0;
        background: var(--primary);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--bg-second);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }

  @mixin desk-md {
    .cont {
      max-width: 1680px;
    }
  }

  @mixin min-media 2200 {
    .cont {
      max-width: 2060px;
    }
  }
}

/* end of Common desktop header */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.mobile-header {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: 100vh;
  min-width: 320px;

  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);

  padding-top: 220px;
  padding-bottom: 40px;

  display: none;

  z-index: 100;
  font-family: var(--font-second);

  background: rgba(79, 81, 79, 0.9);
  backdrop-filter: blur(10px);

  transition: transform 0.3s ease-out;

  &.nav-slide-right {
    right: 0;
    left: auto;
    transform: translateX(100%);

    &.active {
      transform: translateX(0);
    }
  }

  @mixin media 651 {
    width: 100%;
  }

  .cont {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .policy {
    margin-top: auto;
    color: var(--white);
    display: flex;
    flex-direction: column;

    a,
    p {
      font-weight: 300;
      font-family: var(--font-main);
      width: fit-content;
    }
  }

  .mobile-menu__link {
    font-size: 30px;
    font-weight: 600;
    color: var(--white);
    transition: color 0.3s ease;

    &:hover {
      color: var(--gold);
    }
  }
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  width: 36px;
  height: 36px;
  background: var(--white);
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;

  span {
    width: 18px;
    height: 2px;
    background: var(--grey);
    transition: all 0.1s;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--grey);

      transition: all 0.1s;
    }

    &::before {
      top: -5px;
    }

    &::after {
      bottom: -5px;
    }
  }

  @mixin transition-all;

  &.active {
    span {
      background: transparent;

      &::before {
        top: 0;
        transform: rotate(-45deg);
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  @mixin transition-all;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* end of HEADER COMPONENTS */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar {
      align-items: flex-start;
    }

    .menu {
      display: none;
    }

    .logo {
      transform: none;

      &:hover {
        transform: scale(1.06);
      }
    }
  }

  .mobile-header {
    display: block;
  }
}

/* end of Common desktop header Mobile HEADER */
