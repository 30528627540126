.testimonial-card {
  background: var(--light-grey);
  padding: 45px 10px 74px 60px;
  max-width: 400px;
  border-bottom: 8px solid var(--gold);
  position: relative;

  &:hover {
    &:before {
      height: 35px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16px;
    background: var(--grey);
    transition: 0.3s ease;
  }

  &__logo {
    margin-bottom: 38px;
    max-width: 132px;
    height: 90px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 90px;
      user-select: none;
      object-position: left;
    }
  }

  &__text {
    font-size: ac(19px, 15px);
    margin-bottom: 38px;
    max-height: 175px;
    padding-right: 20px;

    p {
      font-size: ac(19px, 15px);
      line-height: 156%;
      word-break: break-word;
    }

    .simplebar-scrollbar::before {
      width: 5px;
      border-radius: 0;
    }

    @mixin media 415 {
      max-height: 180px;
    }
  }

  &__partner {
    p {
      font-weight: 300;
    }

    &-name {
      margin-bottom: 8px;
    }
  }

  &.case-study-card {
    padding: 30px 30px 45px;
    min-height: 240px;

    display: flex;
    align-items: center;
    justify-content: center;

    .testimonial-card__logo {
      max-width: 175px;
      margin-bottom: 0;
      height: 120px;

      img {
        max-height: 100%;
        object-position: center;
      }
    }

    .testimonial-card__title {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
    }

    .testimonial-card__text {
      margin-bottom: 0;
      p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 0;
        line-height: 176%;
      }
    }
  }

  @mixin media 769 {
    padding: 30px 15px 52px 20px;

    &__text {
      margin-bottom: 27px;

      p {
        line-height: 175%;
      }
    }

    &__partner {
      p,
      &-name {
        font-size: 13px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}
