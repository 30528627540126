.member-card {
  &__img {
    @mixin aspect-ratio 384, 361;
  }

  &__info {
    background: var(--grey);
    margin-top: -61px;
    width: fit-content;
    padding: 21px 25px;
    z-index: 10;
    position: relative;

    &-title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 4px;
      color: var(--white);

      @mixin desk-md {
        font-size: 25px;
      }
    }

    &-position {
      font-weight: 300;
      font-size: 14px;
      line-height: 131%;
      color: rgba(255, 255, 255, 0.8);

      @mixin desk-md {
        font-size: 18px;
      }
    }
  }

  &__contacts {
    margin-top: 14px;
    display: flex;
    flex-direction: column;

    &-link {
      color: var(--white);
      width: fit-content;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &.social-link {
        span {
          text-decoration: underline;
        }
      }

      span {
        margin-left: 12px;
        font-size: 15px;
        font-weight: 300;
        transition: color 0.3s ease;
        word-break: break-word;

        @mixin desk-md {
          font-size: 18px;
        }

        @mixin media 360 {
          font-size: 13px;
        }
      }

      i {
        color: var(--gold);
        font-size: 15px;
      }

      &:hover {
        span {
          color: var(--gold);
          text-decoration: underline;
        }
      }
    }
  }

  @mixin media 1025 {
    display: flex;
    align-items: center;

    &__img {
      max-width: 400px;
      width: 100%;
    }

    &__info {
      margin-left: -20px;
      max-width: 450px;
      width: 100%;
    }
  }

  @mixin media 551 {
    flex-direction: column;
    align-items: flex-start;

    &__info {
      margin-left: 5vw;
    }
  }

  @mixin media 415 {
    &__info {
      margin-left: 0;
      width: fit-content;
    }
  }
}
