.btn {
  width: 100%;
  max-width: 220px;
  height: 50px;
  padding-right: 42px;
  background: var(--light-grey);
  position: relative;
  font-weight: 500;
  font-family: var(--font-second);

  display: flex;
  align-items: center;
  justify-content: center;

  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: var(--gold);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s ease;

    i {
      transition: 0.25s ease;
      font-size: 14px;
    }
  }

  &.btn-white {
    background: var(--white);
  }

  &:hover {
    .arrow {
      height: 100%;
      right: 0;

      i {
        transform: rotate(-45deg);
      }
    }
  }

  @mixin desk-md {
    height: 60px;
  }
}

.navigation-next,
.navigation-prev {
  transition: 0.3s ease;
  cursor: pointer;

  &:hover {
    &:not(.swiper-button-disabled, .disabled) {
      background: var(--gold);
    }
  }

  &.swiper-button-disabled,
  &.disabled {
    cursor: auto;
    opacity: 0.4;
  }
}
