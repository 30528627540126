@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300;500&family=Kantumruy+Pro:wght@300;500;600&display=swap");

:root {
	--black: #1c1c1c;
	--white: #ffffff;
	--grey: #4f514f;
	--medium-grey: #c9c9c9;
	--light-grey: #f7f7f7;
	--gold: #eace2a;

	--font-main: "Albert Sans", sans-serif;
	--font-second: "Kantumruy Pro", sans-serif;

	/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
	--mobile-menu-start-point: 1200;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 769px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 768px);

@keyframes slide {
	0% {
		transform: translateY(-10px);
	}

	100% {
		transform: translateY(0);
	}
}
